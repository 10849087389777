<template>
  <div id="exchanges">
    <TheHeader></TheHeader>
    <SiteNav></SiteNav>
    <div class="px-3 py-3 bg-lines bg-cover bg-fixed bg-center">

      <NotAvailable v-if="!user.company.features.exchange.available" name="Tauschbörse"></NotAvailable>

      <div v-else class="md:container md:mx-auto">

        <nav class="nav-onpage" id="nav">
          <router-link class="nav-btn" to="../tauschboerse/meine-dienste" active-class="active">
            <font-awesome-icon icon="clipboard-list" class="icon"/>
            <p class="hidden sm:block">Meine Dienste</p>
          </router-link>
          <router-link class="nav-btn" to="../tauschboerse/angebotene-dienste" active-class="active">
            <font-awesome-icon icon="tasks" class="icon"/>
            <p class="hidden sm:block">Angebotene Dienste</p>
          </router-link>
          <router-link class="nav-btn" to="../tauschboerse/verfuegbare-dienste" active-class="active">
            <font-awesome-icon icon="search" class="icon"/>
            <p class="hidden sm:block">Verfügbare Dienste</p>
          </router-link>
        </nav>
        <hr class="nav-onpage-hr" />

        <router-view></router-view>

      </div>
    </div>
  </div>
</template>


<script>
import SiteNav from "@/components/TheNavigation";
import TheHeader from "@/components/TheHeader";
import NotAvailable from "@/components/NotAvailable";

import { mapGetters } from "vuex";

export default {
  name: "Exchanges",
  components: {
    SiteNav,
    TheHeader,
    NotAvailable
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {
    this.$store.dispatch("fetchUserProfile");
  },
  computed: {
    ...mapGetters({
      user: "getUser",
    }),
  },
};
</script>